<template>
  <div class="sendCode">
    <div class="head" @click="$router.go(-1)">
      <svg-icon iconClass="leftArrow" class="backBtn"></svg-icon>
    </div>
    <div class="title">验证码已经发送至</div>
    <div class="phone">{{registerType == 'phone' ? phoneNo : email}}</div>
    <div class="inputBox">
      <van-field placeholder="请输入验证码" type="text" v-model="verifyCode">
        <template #button>
          <div class="time" v-if="timer && timer > 0">{{timer}}S</div>
          <div class="time" v-else @click="countdown">重新发送</div>
        </template>
      </van-field>
    </div>
    <div class="btn" @click="register">注册/登录</div>
  </div>
</template>

<script>
import { captcha, register, getUserInfo } from "@/api/user.js";
export default {
  data() {
    return {
      phoneNo: "",
      email: "",
      areaCode: "",
      verifyCode: "",
      pwd: "",
      registerType: "",
      timer: 120,
      isGetCode: true,
    };
  },
  created() {
    let registerInfo = JSON.parse(sessionStorage.getItem("registerInfo"));
    this.phoneNo = registerInfo ? registerInfo.phoneNo : "";
    this.areaCode = registerInfo ? registerInfo.areaCode : "";
    this.pwd = registerInfo ? registerInfo.pwd : "";
    this.email = registerInfo ? registerInfo.email : "";
    this.registerType = registerInfo ? registerInfo.registerType : "phone";
    let countdown = setInterval(() => {
      this.timer--;
      if (this.timer <= 0) {
        clearInterval(countdown);
        this.isGetCode = false;
      }
    }, 1000);
    this.countdown();
  },
  methods: {
    // 倒计时
    countdown() {
      if (this.isGetCode) return;
      this.isGetCode = true;
      this.sendCode();
      this.timer = 120;
      let countdown = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          clearInterval(countdown);
          this.isGetCode = false;
        }
      }, 1000);
    },
    // 发送验证码
    async sendCode() {
      let emailReg =
        /^([A-Za-z0-9_\-\\.])+\\@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,5})$/;
      if (!this.phoneNo && this.registerType == "phone") {
        this.$toast("发送失败，请返回注册页面重新注册");
        return;
      }
      if (!emailReg.test(this.email) && this.registerType == "email") {
        this.$toast("发送失败，请返回注册页面重新注册");
        return;
      }
      let req = {
        mobile:
          this.registerType == "phone"
            ? "+" + this.areaCode + this.phoneNo
            : undefined,
        email: this.registerType == "email" ? this.email : undefined,
      };
      let ret = await this.$Api(captcha, req);
      if (ret && ret.code != 200) {
        this.$toast(ret.tip || "验证码发送失败");
        return;
      }
    },
    // 注册
    async register() {
      if (!this.verifyCode) {
        this.$toast("请输入验证码");
      }
      this.$toast.loading({
        duration: 0,
      });
      let req = {
        code: this.verifyCode,
        mobile:
          this.registerType == "phone"
            ? "+" + this.areaCode + this.phoneNo
            : undefined,
        email: this.registerType == "email" ? this.email : undefined,
        passWord: this.pwd,
        devID: localStorage.getItem("Uid"),
        devType: 2,
      };
      if (req.code.trim() == "") {
        this.$toast("验证码不能为空");
        return;
      }
      let ret = await this.$Api(register, req);
      this.$toast.clear();
      if (ret && ret.code == 200) {
        localStorage.setItem("Authorization", ret.data.token);
        sessionStorage.removeItem("registerInfo");
        this.getUserInfo();
      } else {
        this.$toast(ret.tip || "注册失败");
      }
    },
    // 获取用户信息
    async getUserInfo() {
      let ret = await this.$Api(getUserInfo, undefined);
      if (ret && ret.code == 200) {
        sessionStorage.setItem("userInfo", JSON.stringify(ret.data.info)); //存储自己的用户信息
        let toPath = sessionStorage.getItem("toPath");
        if (toPath) {
          this.$router.push(toPath);
        } else {
          this.$router.go(-2);
        }
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.sendCode {
  background: #f6f6f6;

  .head {
    width: 48px;
    height: 44px;
    display: flex;
    align-items: center;

    .backBtn {
      width: 16px;
      height: 16px;
      margin: 0 16px;
    }
  }

  .title {
    font-size: 18px;
    font-weight: 600;
    margin: 9px 0 0 49px;
  }

  .phone {
    font-size: 16px;
    margin: 14px 0 24px 49px;
  }

  .inputBox {
    overflow: hidden;
    width: 278px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid $greyishBrown;
    margin: 0 auto;
    background: $white1;
    display: flex;
    align-items: center;

    .time {
      color: $vermillion;
    }
  }

  .btn {
    width: 278px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    border-radius: 27px;
    margin: 69px auto 0;
    color: $white1;
    background: $vermillion;
    text-align: center;
  }
}
</style>